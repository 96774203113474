import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Masonry from "../../components/masonry"
import { graphql } from "gatsby"

export default ({ data }) => (
  <Layout>
    <SEO title="Landscape" />

    <section className="hero is-medium is-white">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-spaced is-size-3">Landscape</h1>
          <h3 className="is-size-5">
            Since moving from Townsville to the Tablelands it is inevitable to
            be drawn into the amazing landscapes and wildlife in this region. This collection
            is but a small sample of the variety of landscapes captured over the
            last two years. All the landscape images are available for purchase
            in any size print to suit your décor.
          </h3>
        </div>
      </div>
    </section>
    <section>
      <Masonry
        items={data.landscape.edges.map(({ node }) => ({
          image: { ...node.childImageSharp.fluid },
          publicURL: node.publicURL,
          name: node.name,
        }))}
        itemsPerRow={[1, 5]}
      />
    </section>
  </Layout>
)

export const data = graphql`
  query {
    landscape: allFile(
      filter: { relativeDirectory: { eq: "gallery/landscape" } }
    ) {
      edges {
        node {
          id
          publicURL
          name
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
